.user-information {
    background-color: #f8f8f8;
    height: 100vh;
    overflow-x: hidden;
    /* height: 100%; */
}

.information-page-wrapper {
    background-color: #f8f8f8;
    height: 100vh;
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.account-page-wrapper {
    background-color: #f8f8f8;
    /* min-height: 100vh; */
    height: 100vh;
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: column;
}

.mochi-info-icon {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 150px;
}

.information-title-wrapper {
    background-color: #ffcb08;
    border-radius: 0 0 15px 15px;
    width: 100%;
    height: 50px;
    text-align: center;
    position: relative;
    /* display: flex; */
    align-items: center;
}

.info-title {
    font-weight: 700;
    font-size: 20px;
    padding-top: 10px;
}

.info-close-icon {
    height: 35px;
    position: absolute;
    left: 10px;
    top: 5px;
    cursor: pointer;
}

.user-info-wrapper {
    display: flex;
    width: 60%;
    margin: 30px auto 50px auto;
    justify-content: space-evenly;
    align-items: center;
}

.user-info-wrapper-not-login {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: 10px auto 50px auto;
    justify-content: space-evenly;
    align-items: center;
}

.user-info-wrapper-not-login p {
    font-weight: 600;
    margin-bottom: 10px;
    text-align: center;
}

.avatar-wrapper {
    text-align: center;
    display: flex;
    flex-direction: column;
}

.paid-bg {
    background: linear-gradient(83.47deg, #ffcb08 9.02%, #ff9600 90.81%);
    padding: 3px;
}

.free-bg {
    background: linear-gradient(83.47deg, #58cc02 9.02%, #23ac38 90.81%);
    padding: 3px;
}

.user-avatar {
    object-fit: cover;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    z-index: 9;
}

.user-account {
    min-width: 115px;
    width: 15%;
    border-radius: 10px;
    margin: -15px auto 10px auto;
    text-align: center;
    font-weight: 600;
    z-index: 100;
    color: #fff;
    font-size: 12px;
}

.info-wrapper h5 {
    font-weight: 700;
    font-size: 20px;
}

.info-div {
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-top: 5px;
}

.info-div img {
    margin-right: 10px;
}

.boldText {
    font-weight: 700;
}

.setting-dictionary,
.setting-new-tab {
    display: flex;
    align-items: center;
    width: 70%;
    margin: 20px auto;
    justify-content: space-between;
}

.dict-select,
.dict-option {
    /* -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; */
    /* padding: 5px; */
    padding: 10px 20px;
    background: #ffcb08;
    border: 1px solid #fff;
    border-radius: 10px;
    width: 220px;
    cursor: pointer;
}

.dict-option {
    height: 30px;
}

.dict-select:focus {
    outline: none;
}

.setting-new-tab .switch {
    cursor: pointer;
}

.setting-new-tab .toggle {
    isolation: isolate;
    position: relative;
    height: 22px;
    width: 38px;
    border-radius: 15px;
    background: #d6d6d6;
    overflow: hidden;
}

.setting-new-tab .toggle-inner {
    z-index: 2;
    position: absolute;
    top: 3px;
    left: 3px;
    height: 16px;
    width: 32px;
    border-radius: 15px;
    overflow: hidden;
}

.setting-new-tab .active-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 200%;
    background: linear-gradient(83.47deg, #58cc02 9.02%, #23ac38 90.81%);
    transform: translate3d(-100%, 0, 0);
    transition: transform 0.05s linear 0.17s;
}

.setting-new-tab .toggle-state {
    display: none;
}

.setting-new-tab .indicator {
    height: 100%;
    width: 200%;
    background: white;
    border-radius: 13px;
    transform: translate3d(-75%, 0, 0);
    transition: transform 0.35s cubic-bezier(0.85, 0.05, 0.18, 1.35);
}

.toggle-state:checked ~ .active-bg {
    transform: translate3d(-50%, 0, 0);
}

.toggle-state:checked ~ .toggle-inner .indicator {
    transform: translate3d(25%, 0, 0);
}

.setting-new-tab .switch {
    display: inline-flex;
    align-items: center;
}

.setting-new-tab .switch p {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 600;
}

.info-web-wrapper {
    text-align: center;
}

.info-web-wrapper .mochi_primary_btn_nt {
    width: 200px;
    margin: 10px auto;
}

.logOutText {
    text-decoration: underline;
    color: #ccc;
    margin: 10px auto;
    text-align: center;
    cursor: pointer;
}

.login-third-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.login-third-wrapper h5 {
    font-weight: 700;
    margin: 15px auto;
    text-align: center;
}

.login-third-button {
    min-width: 150px;
    width: 80%;
    margin: 10px auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 18px;
    color: #fff;
}

.google-icon {
    width: 30px;
    height: 20px;
}

.google-btn {
    background-color: #d21919 !important;
    filter: drop-shadow(0px 4px 0px #a80d0dd7) !important;
    color: #fff !important;
    font-weight: 700 !important;
    font-size: 18px !important;
    border-radius: 25px !important;
}

.google-btn span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
}

.facebook-btn {
    background-color: #007bfe !important;
    filter: drop-shadow(0px 4px 0px #0756aa) !important;
    padding: 10px !important;
    border-radius: 25px !important;
    margin: 5px auto !important;
    font-weight: 600 !important;
    color: #fff !important;
    font-size: 18px !important;
}

.facebook-icon {
    width: 12px;
    /* height: 20px; */
}

.apple-btn {
    background-color: #000000 !important;
    filter: drop-shadow(0px 4px 0px #3b38387e) !important;
    color: #fff !important;
    font-size: 18px !important;
}

.apple-icon {
    width: 20px;
    /* height: 20px; */
}

.login-form-wrapper {
    margin: 0px auto 80px auto;
    display: flex;
    flex-direction: column;
    width: 50%;
    text-align: center;
}

.form-login-input {
    width: 100%;
    padding: 20px;
    margin: 10px 0;
    border-radius: 10px;
}

@media (min-width: 342px) and (max-width: 868px) {
    .form-login-input::placeholder {
        font-size: 12px;
    }

    .hide-show-btn {
        font-size: 11px;
    }
}

@media (min-width: 1023px) and (max-width: 1325px) {
    .form-login-input::placeholder {
        font-size: 11px;
    }

    .hide-show-btn {
        font-size: 11px;
    }
}

.form-login-input:focus {
    outline: 1px solid #ddd;
}

.password-input-wrapper {
    position: relative;
    width: 100%;
    margin-bottom: 15px;
    text-align: left;
}

.hide-show-btn {
    position: absolute;
    top: 38%;
    right: 16px;
    font-size: 12px;
    color: #52ad39;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 700;
}

.error-text {
    color: #d21919;
    font-size: 14px;
}

/* .input-warning:focus {
    outline: 2px solid #D21919;
} */
.input-warning {
    border: 2px solid #d21919;
}

.login-button {
    width: 50%;
    margin: 5px auto;
    text-align: center;
}

.forget-pass,
.register-btn {
    margin-top: 10px;
    font-weight: 700;
    color: #2f80ed;
    text-decoration: underline;
    cursor: pointer;
    font-size: 15px;
}

.forget-pass:hover,
.register-btn:hover {
    color: #1d65c4;
}

.register-wrapper {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 600;
}

.account-wrapper {
    justify-content: space-evenly;
}

.email-input-wrapper {
    text-align: left;
}

.forget-notice-wrapper {
    margin: 30px auto;
    min-width: 200px;
    width: 80%;
}

.inbox-button {
    width: 60%;
    margin: 20px auto;
}

.register-icon {
    width: 250px;
    margin: 10px auto;
}

.create-acc-btn {
    color: #000000;
}

.register-form-wrapper {
    width: 100%;
    margin-bottom: 90px;
}

.info-setting-wrapper,
.info-web-wrapper {
    margin: 30px 0;
}

@media (min-height: 850px) {
    .info-setting-wrapper,
    .info-web-wrapper {
        min-height: 200px;
    }

    .logOutText {
        margin-top: 100px;
    }
}

.user-avatar.not-login {
    width: 140px;
    height: 140px;
    background: none;
}

*, p {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-family: 'Quicksand', sans-serif;
}

.mochi-new-tab_main {
    text-align: center;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}

.mochi-new-tab_container.container {
    min-width: 100%;
    /* padding: 20px 20px 0 20px; */
}

.marginTop20 {
    margin-top: 20px;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}


@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
